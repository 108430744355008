import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Sticky from "react-sticky-el"
import { ShoppingConsumer } from "../layouts/index"
import { Section } from "../components/CompoundComponents/Section"
import {
  Card,
  CardContent,
  CardContentTitle,
} from "../components/CompoundComponents/Card"
import { Button } from "../components/CompoundComponents/Button"
import Contact from "../components/Sections/Contact"
import NavBar from "../components/navbar"
import localize from "../components/localize";

const BlogPost = ({ pageContext, data }) => {
  const products = pageContext.products;
  const contact = data.contacts.edges[0].node;
  const address = data.address.edges;

  return (
      <>
        <Sticky className="sticky-selector" topOffset={150}>
          <div className="is-shadow">
            <NavBar data={data.menu} />
          </div>
        </Sticky>
          <ShoppingConsumer>
              {({ cart, handleAddToCart, lang }) => (
                  <>
                      <Section white>
                          <div className="columns is-vcentered is-multiline">
                              <div className="column is-5 is-offset-1">
                                  <GatsbyImage
                                      image={getImage(products.image.asset.gatsbyImageData)}
                                  />
                              </div>
                              <div className="column is-6">
                                  {
                                      <Card transparent isShadowless>
                                          <CardContent>
                                              <CardContentTitle>{lang === 'ru' ? products.name.ru : products.name.en}</CardContentTitle>
                                              <div style={{fontSize: '15px', marginTop: '10px'}}>{lang === 'ru' ? products?.description?.ru : products?.description?.en}</div>
                                          </CardContent>
                                          <footer className="card-footer">
                                              {cart.filter(e => e === (lang === 'ru' ? products.name.ru : products.name.en)).length > 0 ? (
                                                  <div className="card-footer-item">
                                                      <Link to="/contact">
                                                          <Button>
                            <span>
                              Оформить
                            </span>
                                                          </Button>
                                                      </Link>
                                                  </div>
                                              ) : (
                                                  <div className="card-footer-item">
                                                      <button
                                                          onClick={() => handleAddToCart(lang === 'ru' ? products.name.ru : products.name.en)}
                                                          className="button hvr-bounce-to-right is-size-6-mobile is-success is-outlined has-text-weight-bold is-normal has-family-pt-sans"
                                                      >
                                                          {data.product.edges[0].node.section_button}
                                                      </button>
                                                  </div>
                                              )}
                                          </footer>
                                      </Card>
                                  }
                              </div>
                          </div>
                      </Section>
                  </>
              )}
          </ShoppingConsumer>
        <Contact contact={contact} address={address} />
      </>)
}

export default localize(BlogPost);

export const BlogPostQuery = graphql`
  query {
  product: allSanityProductpage {
        edges {
          node {
            section_button {
              _type
              en
              ru
            }
            section_button_2 {
              _type
              en
              ru
            }
          }
        }
      }
      menu: allSanityMenu {
      edges {
        node {
           name {
             _type
             en
             ru
           }
         }
       }
     }
     
     contacts: allSanityContact {
        edges {
          node {
            copyright {
              en
              ru
              _type
            }
            phone {
              en
              ru
            }
            phone_numbers {
              _type
              en
              ru
            }
            social {
              _type
              en
              ru
            }
            textarea {
              _type
              en
              ru
            }
            our_contacts {
              _type
              en
              ru
            }
            name {
              _type
              en
              ru
            }
            address {
              _type
              en
              ru
            }
            button {
              _type
              en
              ru
            }
            copyright {
              _type
              en
              ru
            }
            email {
              _type
              en
              ru
            }
            email_title {
              _type
              en
              ru
            }
            leave_request {
              _type
              en
              ru
            }
          }
        }
      }
      
      address: allSanityAddress {
        edges {
          node {
            address {
              en
              ru
              _type
            }
          }
        }
      }
  }
`